/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import * as d3 from 'd3';

import {
  PerformanceChart,
  PerformanceFilterRange,
  PerformanceGraphItem,
  PerformanceTransactionItem,
  PerformanceType
} from '@shared/models/Home';

import {
  createSvg,
  generateBottomAxis,
  getBalanceTooltip,
  getBisect,
  getChartMaxMinValues,
  getChartStartEndValues,
  getCssValueFromDom,
  getDepositsAndWithdrawals,
  getPerformanceFilters,
  getPerformanceTooltip,
  getXScale,
  getYScale,
  PerformanceColors,
  renderLine
} from './home-performance-chart.util';

@Component({
  selector: 'app-home-performance-chart',
  templateUrl: './home-performance-chart.component.html',
  styleUrls: ['./home-performance-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomePerformanceChartComponent implements OnInit, OnChanges {
  @Input() currency: string;
  @Input() language: string;
  @Input() kind: '3b' | '3a' | 'kid' | 'ci';
  @Input() chartData: PerformanceGraphItem[];
  @Input() firstPerformanceDate: string;
  @Input() performance: PerformanceChart;
  @Output() filterChange: EventEmitter<PerformanceFilterRange> = new EventEmitter<PerformanceFilterRange>();
  @Output() typeChange: EventEmitter<PerformanceType> = new EventEmitter<PerformanceType>();

  performanceFilters: { [key: string]: string | number }[];
  height = 152;
  axisBottom = null;
  svg: d3.Selection<d3.BaseType, unknown, HTMLElement, unknown>;
  xScale;
  yScale;
  xStartScale;
  xEndScale;
  width = window.innerWidth;
  showTooltip = false;
  showInfo = false;
  is3bProduct = true;
  lastSelectedAccount = null;

  tabs: { value: string; id: string }[];

  tooltipData: {
    date: Date;
    performance: { percentage: number }[];
    balance: { amount: number; deposits: number; withdrawals: number };
  };

  get hasPositivePerf() {
    return this.chartData[this.chartData.length - 1].value > 0;
  }

  get isPerformanceChart(): boolean {
    return this.performance.chartType === PerformanceType.PERFORMANCE;
  }

  get performanceChartColor() {
    return this.hasPositivePerf ? PerformanceColors.POSITIVE : PerformanceColors.NEGATIVE;
  }

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.tabs = [
      { value: this.currency.toUpperCase(), id: PerformanceType.BALANCE },
      { value: '%', id: PerformanceType.PERFORMANCE }
    ];
    this.svg = createSvg('.performance-chart', this.width, this.height);

    this.is3bProduct = this.kind !== '3a';
    this.drawChart(this.chartData);
    this.lastSelectedAccount = this.kind;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.kind && !changes.chartData.firstChange) {
      this.is3bProduct = changes.kind?.currentValue !== '3a';
      // if account is not the same hide the tooltip
      if (changes.kind.currentValue !== this.lastSelectedAccount) {
        this.hideRules();
      }
    }

    if (changes.language && !changes.language.firstChange) {
      this.drawChart(this.chartData, true);
    }

    if (changes.chartData && !changes.chartData.firstChange) {
      this.drawChart(changes.chartData.currentValue, true);
    }
  }

  drawChart(chartData: PerformanceGraphItem[], updated: boolean = false) {
    this.performanceFilters = getPerformanceFilters(this.firstPerformanceDate);
    this.cd.markForCheck();

    const { min, max } = getChartMaxMinValues(chartData);

    const { deposits, withdrawals } = getDepositsAndWithdrawals(chartData, this.performance.chartType, this.performance.range);

    const chartMargin = Number(getCssValueFromDom('--spacing-4').replace('px', ''));

    // scale the graph based on the size
    this.xScale = getXScale(chartData, chartMargin, this.width - chartMargin);
    this.yScale = getYScale(min, max, this.height);

    // start & end lines data
    const { startItems, endItems } = getChartStartEndValues(chartData);
    this.xStartScale = getXScale(startItems, 0, chartMargin - 1);
    this.xEndScale = getXScale(endItems, this.width - chartMargin + 1, this.width);

    // Transition
    const duration = 500;
    const t = d3.transition().duration(duration);

    const zeroLine = this.svg.selectAll('.performance-zero').data([0]);
    zeroLine.data([0]).join(
      (enter) =>
        enter
          .append('line')
          .attr('class', 'performance-zero')
          .attr('x1', 0)
          .attr('x2', this.width)
          .attr('y1', this.yScale(this.isPerformanceChart ? 0 : min))
          .attr('y2', this.yScale(this.isPerformanceChart ? 0 : min))
          .attr('stroke', `var(${PerformanceColors.CORE_NEUTRAL_4})`)
          .style('stroke-dasharray', '2, 2'),
      (update) =>
        update
          .transition(t)
          .attr('x1', 0)
          .attr('x2', this.width)
          .attr('y1', this.yScale(this.isPerformanceChart ? 0 : min))
          .attr('y2', this.yScale(this.isPerformanceChart ? 0 : min)),
      (exit) => exit.remove()
    );

    const lines = [
      { name: 'line', data: chartData, xScaleFn: this.xScale },
      { name: 'start', data: startItems, xScaleFn: this.xStartScale },
      { name: 'end', data: endItems, xScaleFn: this.xEndScale }
    ];

    lines.forEach(({ name, data, xScaleFn }: { name: string; data: PerformanceGraphItem[]; xScaleFn: (value: any) => any }) => {
      if (this.is3bProduct) {
        // render benchmark lines
        renderLine(
          `benchmark-${name}`,
          this.svg,
          this.isPerformanceChart ? data : [],
          PerformanceColors.BENCHMARK,
          null,
          1.5,
          xScaleFn,
          this.yScale,
          'benchmarkValue'
        );
        // update z-index
        this.svg.select(`.benchmark-${name}`).lower();
      } else {
        this.svg.select(`.benchmark-${name}`).remove();
      }

      // render performance lines
      renderLine(
        `performance-${name}`,
        this.svg,
        data,
        this.performanceChartColor,
        this.isPerformanceChart ? this.performanceChartColor : PerformanceColors.POSITIVE,
        2,
        xScaleFn,
        this.yScale,
        'value'
      );
    });

    // Deposits & Withdrawals
    this.svg.selectAll('.perf-transactions').data([0]).enter().append('g').attr('class', 'perf-transactions');

    this.svg
      .select('.perf-transactions')
      .selectAll('.perf-deposit')
      .data(deposits)
      .join(
        (enter) =>
          enter
            .append('circle')
            .attr('class', 'perf-deposit')
            .attr('cx', (d: any) => this.xScale(d.date))
            .attr('cy', (d: any) => this.yScale(d.value))
            .attr('fill', `var(${PerformanceColors.BENCHMARK})`)
            .attr('stroke', `var(${PerformanceColors.BACKGROUND}`)
            .attr('stroke-width', 1)
            .attr('r', 0)
            .transition(t)
            .attr('r', 5),
        (update) =>
          update
            .attr('cx', (d: any) => this.xScale(d.date))
            .attr('cy', (d: any) => this.yScale(d.value))
            .attr('r', 0)
            .transition()
            .duration(300)
            .attr('r', 5),
        (exit) => exit.transition().duration(200).attr('r', 0).remove()
      );

    this.svg
      .select('.perf-transactions')
      .selectAll('.perf-withdrawal')
      .data(withdrawals)
      .join(
        (enter) =>
          enter
            .append('circle')
            .attr('class', 'perf-withdrawal')
            .attr('cx', (d: any) => this.xScale(d.date))
            .attr('cy', (d: any) => this.yScale(d.value))
            .attr('r', 0)
            .attr('stroke', `var(${PerformanceColors.BENCHMARK}`)
            .attr('stroke-width', 2)
            .attr('fill', `var(${PerformanceColors.BACKGROUND})`)
            .transition(t)
            .attr('r', 4),
        (update) =>
          update
            .attr('cx', (d: any) => this.xScale(d.date))
            .attr('cy', (d: any) => this.yScale(d.value))
            .attr('r', 0)
            .transition()
            .duration(300)
            .attr('r', 4),
        (exit) => exit.transition().duration(200).attr('r', 0).remove()
      );

    // Vertical ruler
    const perfRuler = this.svg.selectAll('.perf-graph__ruler').data([0]);
    perfRuler
      .enter()
      .append('g')
      .attr('class', 'perf-graph__ruler')
      .style('opacity', 0)
      .append('line')
      .attr('y1', this.height)
      .attr('y2', 0)
      .attr('stroke', `var(${PerformanceColors.CORE_BRAND_3_3})`);

    // Ruler dot
    const perfDot = this.svg.selectAll('.perf-graph__dot').data([0]);

    const perfDotGroup = perfDot.enter().append('g').attr('class', 'perf-graph__dot').style('opacity', 0);

    perfDotGroup.append('circle').attr('r', 6).attr('fill', `var(${PerformanceColors.BACKGROUND})`);
    perfDotGroup
      .append('circle')
      .attr('class', 'perf-dot')
      .attr('r', 4)
      .attr('stroke', `var(${this.performanceChartColor})`)
      .attr('stroke-width', 2)
      .attr('fill', `var(${PerformanceColors.BACKGROUND})`);

    perfDot.select('.perf-dot').attr('stroke', `var(${this.isPerformanceChart ? this.performanceChartColor : PerformanceColors.POSITIVE})`);

    perfDot.raise();

    // xBottomAxis
    if (this.axisBottom !== null) {
      this.svg.select('.perf-labels').remove();
    }

    this.axisBottom = this.svg
      .append('g')
      .attr('class', 'perf-labels')
      .attr('transform', `translate (0, ${this.height})`)
      .call(generateBottomAxis(this.xScale, chartData, this.language))
      .call((g) => g.select('.domain').remove())
      .call((g) => g.selectAll('.tick line').remove());

    const nodes = this.axisBottom.selectAll('text').attr('class', 'ds-ui-3').style('fill', `var(${PerformanceColors.BENCHMARK})`).nodes();

    d3.select(nodes[0]).attr('text-anchor', 'start');
    d3.select(nodes[nodes.length - 1]).attr('text-anchor', 'end');

    this.addEvents(chartData, this.svg, this.xScale, updated);
  }

  addEvents(
    chartData: PerformanceGraphItem[],
    svg: d3.Selection<d3.BaseType, unknown, HTMLElement, unknown>,
    xScaleFn: (param) => any,
    updated: boolean
  ) {
    if (updated) {
      this.svg.select('.performance-rect').remove();
    }

    const eventRect = this.svg
      .append('rect')
      .attr('class', 'performance-rect')
      .attr('width', this.width)
      .attr('height', this.height)
      .attr('fill', 'transparent');

    eventRect.on('mousemove touchmove', (event) => {
      this.viewChart(event, eventRect, chartData, xScaleFn);
    });

    eventRect.on('mouseout touchend', (event) => {
      this.hideRules();
    });

    this.showFirstItem(chartData, xScaleFn);
  }

  hideRules() {
    this.showTooltip = false;
    d3.select('.perf-graph__ruler').attr('transform', 'translate(0, 0)').style('opacity', 0);
    d3.select('.perf-graph__dot').attr('transform', `translate(0, 0)`).style('opacity', 0);
    this.cd.detectChanges();
  }

  showFirstItem(chartData, xScaleFn: (param) => any) {
    const lastItem = chartData[chartData.length - 1];
    const { date, value, benchmarkValue, transactions } = lastItem;
    this.drawTooltip({ date, value, benchmarkValue, transactions, xScaleFn });
  }

  viewChart(event, elem, chartData: PerformanceGraphItem[], xScaleFn: (param) => any) {
    // distinguish event touchmove or mouse move
    const expectedEv = window.TouchEvent && event instanceof TouchEvent ? event.touches[0] : event;
    const [mx, my] = d3.pointer(expectedEv, elem.node());

    const { date, value, benchmarkValue, transactions } = getBisect(mx, chartData, xScaleFn);

    this.drawTooltip({ date, value, benchmarkValue, transactions, xScaleFn });
  }

  drawTooltip({ date, value, benchmarkValue, transactions, xScaleFn }) {
    this.showTooltip = !!date;

    this.tooltipData = {
      ...this.tooltipData,
      date: date as Date,
      ...(this.isPerformanceChart && {
        performance: getPerformanceTooltip(value as number, benchmarkValue as number)
      }),
      ...(!this.isPerformanceChart && {
        balance: getBalanceTooltip(value as number, transactions as PerformanceTransactionItem[])
      })
    };

    const xScaledDate = xScaleFn(date);

    d3.select('.perf-graph__ruler')
      .attr('transform', `translate(${(xScaledDate as number) + 0.5}, 0)`)
      .style('opacity', 1);

    d3.select('.perf-graph__dot')
      .attr('transform', `translate(${xScaledDate as number},  ${this.yScale(value)})`)
      .style('opacity', 1);
    this.cd.detectChanges();
  }

  onFilterChange(filter: PerformanceFilterRange) {
    if (filter === this.performance.range || this.performance.loading) return;
    this.filterChange.emit(filter);
  }

  onTypeChange(selectedTab: PerformanceType) {
    if (selectedTab === this.performance.chartType || this.performance.loading) return;
    this.hideRules();
    this.typeChange.emit(selectedTab);
  }

  onToggleInfo() {
    this.showInfo = !this.showInfo;
  }
}
