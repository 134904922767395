import { RiskQuestionsSurveyOption, PortfolioAllocationItem } from './Strategy';

export interface InyovaGrowAccount {
  id: string;
  currency: string;
  customer_id: number;
  status: InyovaGrowAccountStatus;
  initial_investment: number | null;
  minimum_investment: number;
  monthly_investment: number | null;
  projects_percentage: number | null;
  iban: string | null;
  investment_risk_consent: boolean;
  fees_consent: boolean;
  customer_name: string;
  reporting_timestamp: string;
  portfolio_value: number;
  combined_expected_return: number;
  inyova_and_custody_fees: number;
  product_costs: number;
  edit_risk_profile: boolean;
  eur_exchange_rate: number;
}

export interface InyovaGrowInterestAccountProject {
  type: string;
  id: string;
  interest_account_id: string;
  impact_project_id: number;
  amount: null | number;
  documents_reviewed_at: null | string;
  general_conditions_accepted_at: null | string;
  waiver_agreed_at: null | string;
  status: InyovaGrowProjectStatus;
  recommended_projects_amount: number | null;
  top_up_amount: null | number;
  maximum_projects_amount: number | null;
  portfolio_value_in_project_currency: number | null;
  projects_value_in_project_currency: number | null;
  documents_reviewed: boolean;
  general_conditions_accepted: boolean;
  waiver_agreed: boolean;
  risk_agreed: null | boolean;
}

export interface InyovaGrowProject {
  id: string;
  title: string;
  description: string;
  general_agreements_details: null | string;
  opportunities_description: string;
  risk_description: string;
  impact_description: string;
  end_date: string;
  expected_return: number;
  image: string;
  payout: string;
  fact_sheet: string;
  terms_conditions_sheet: string;
  term_sheet: string;
  bonus_payment: string;
  bonus_payment_info: string;
  minimum_funding: string | number;
  currency: string;
  deadline: string;
  total_volume: number;
  asset_type: string;
  sector: string;
  location: string;
  issuer: string;
  inyova_volume?: number;
  volume_secured_from_customers?: number;
  increment_step: number | null;
  investment_documents?: { title: string; url: string }[];
  stage: InyovaGrowProjectStage;
  interest_account_project: InyovaGrowInterestAccountProject;
  cash_percentage: number;
}

export interface InyovaGrowRisk {
  type: string;
  id: number;
  risk_survey?: {
    type: string;
    id: number;
    name: string;
    survey_questions: InyovaGrowRiskSurveyQuestion[];
  };
  financial_detail: InyovaGrowFinancialDetail;
  relationshipNames: string[];
}

export interface InyovaGrowRiskSurveyQuestion {
  hint: string;
  id: number;
  inyova_id?: number;
  question_text: string;
  subtype?: string;
  survey_question_options: RiskQuestionsSurveyOption[];
  survey_customer_answers?: null | { id: number; answer_value: number | number[] };
  type: string;
  too_long_options?: boolean;
  hasAnswer?: boolean;
  checkCount?: number;
}

export interface InyovaGrowRiskGroups {
  mandatory: InyovaGrowRiskSurveyQuestion;
  optionalList: InyovaGrowRiskSurveyQuestion;
  optional: InyovaGrowRiskSurveyQuestion[];
  singleChoice: InyovaGrowRiskSurveyQuestion[];
  multiChoices: InyovaGrowRiskSurveyQuestion[];
}

export interface InyovaGrowPercentages {
  calculated_projects_percentage: number;
  id: string;
  maximum_interest: number;
  minimum_interest: number;
  money_market_interest: number;
  recommended_projects_percentage: number;
  type: string;
}

export type InyovaGrowOnboarding = InyovaGrowRisk & { recommended_projects_percentage: InyovaGrowPercentages };

export interface InyovaGrowFund {
  asset_class: string;
  custodian_bank: string;
  description: string;
  expected_return: number;
  fund_currency: string;
  status: InyovaGrowProjectStatus;
  title: string;
  face_sheet: string;
  prospectus: string;
  annual_report: string;
  key_information_document: string;
  manager_document: string;
  notice_to_shareholders: string;
  amount: null | number;
}

export interface InyovaGrowFinancialDetail {
  freely_cash_available: null | number;
  id: string;
  monthly_income: null | number;
  type: string;
}

// Todo: 29.04.2024 removed selected status
export enum InyovaGrowProjectStatus {
  INITIAL = '',
  AUTO = 'auto',
  SELECTED = 'selected',
  INTERESTED = 'interested',
  COMMITTED = 'committed',
  IN_TRANSIT = 'in_transit',
  INVESTED = 'invested',
  REPAID = 'repaid'
}

export enum InyovaGrowProjectStage {
  ANNOUNCED = 'announced',
  INVESTABLE = 'investable',
  CANCELLED = 'cancelled',
  CLOSED = 'closed_for_funding',
  RUNNING = 'running',
  ENDED = 'ended',
  DEFAULTED = 'defaulted'
}

export enum InyovaGrowAccountStatus {
  INITIAL = 'initial',
  OPENED = 'opened',
  FUNDED = 'funded',
  CLOSED = 'closed'
}

export interface InyovaGrowSurveyQuestion {
  id: number;
  survey_customer_answers: number | number[];
}

export interface InyovaGrowSubmit {
  interestAccount: {
    id: string;
    type: 'interest_account';
    initial_investment: number;
    monthly_investment: number;
    projects_percentage: number;
    investment_risk_consent: boolean;
    fees_consent: boolean;
  };
  riskSurvey: {
    type: 'risk_survey';
    survey_questions: InyovaGrowSurveyQuestion[];
  };
  financialDetail: {
    id: string;
    type: 'financial_detail';
    monthly_income: number;
    freely_cash_available: number;
  };
}

export type InyovaGrowSubmitResponse = InyovaGrowRisk & { interest_account: InyovaGrowAccount };

export interface GrowPortfolioAllocation {
  id?: string;
  type?: string;
  invested_in_projects: PortfolioAllocationItem;
  committed_to_projects: PortfolioAllocationItem;
  uncommitted_in_mmf: PortfolioAllocationItem;
  uncommitted_cash: PortfolioAllocationItem;
}
