import { Customer, CustomerAccount } from '@inyova/models';
import * as dayjs from 'dayjs';

import { Action, createReducer, createSelector, on } from '@ngrx/store';
import * as RootActions from './../app.actions';
import * as AccountActions from './account.actions';

import { Documents3APagination, MAXIMUM_3A_CONTRIBUTION } from '@app/app.constants';
import {
  ABTest,
  AccountDocument,
  Address,
  Documents3AStore,
  FinancialSituation,
  GrowDocument,
  ProfileBannerItem,
  Promotions,
  StaticDocument,
  Transaction,
  TransactionsView
} from '@shared/models/Account';
import { State } from '@shared/models/State';

export const ACCOUNT_REDUCER_FEATURE_KEY = 'account';

export const initialState: AccountState = {
  switchAccountLoading: {
    show: false,
    message: ''
  },
  address: {
    city: '',
    country: '',
    number: '',
    street: '',
    zip: ''
  },
  customer: {
    interview_widget: false,
    inyova_pro: false,
    superhuman_visible: false,
    accounts: [],
    total_current_amount: 0,
    app_location: 'ch',
    created_at: '',
    email: '',
    first_name: '',
    id: 0,
    inyova_id: 0,
    language: 'de-CH',
    last_name: '',
    lead_source: '',
    otp_module: 'disabled',
    phone: '',
    verified_phone: '',
    unverified_phone: '',
    provider: '',
    source_type_id: 3,
    tag_manager: { origin: '', first_time: false },
    is_friend: false,
    referral_code: '',
    total_referrals: {
      free_feedays: 0,
      my_referral_customer: '',
      referrallimit: false
    },
    test_version: '',
    track_id: '',
    uid: '',
    has_crowd_investor_account: false,
    has_interest_account: false,
    saxo_data_renewal_expires_at: null,
    show_interest_account_banner: false
  },
  currentAccount: {
    id: '',
    owner_name: '',
    step_status: 0,
    onboarding_step: 0,
    kind: '3b',
    edit_mode: false,
    strategy_edit_mode_disabled_at: null,
    strategy_edit_mode_enabled_at: null,
    on_hold: false,
    active: true,
    created_at: '',
    product_type: '',
    initial_investment: '',
    current_amount: 0,
    currency: 'CHF',
    customer_journey: {
      account_data_filled: true,
      account_opened: true,
      account_funded: true,
      customer_funded: true,
      compliance_done: true,
      id_verification_completed: true,
      risk_checked: true,
      ql_flow_completed: true
    },
    iban: '',
    is_yova_yellow: false,
    first_time_yellow: false,
    max_contribution_level: 'small',
    minimum_investment: 0,
    deposit_this_year: 0,
    liberty_account: {
      qr_iban: '',
      reference_number: ''
    },
    savings_plan_social_proof: null,
    is_wisher: null,
    liquidation_only: null,
    has_low_investment_balance: true,
    next_strategy_change_on: '',
    inyova_fee: 0,
    ql_onboarding_step: 0,
    risk_recheck_visible: false,
    tutorial_seen: false
  },
  documents3A: {
    initialized: false,
    documents: [],
    pagination: {
      range: Documents3APagination.DEFAULT_RANGE
    }
  },
  documents3B: {
    initialized: false,
    documents: []
  },
  documentsGrow: {
    initialized: false,
    documents: []
  },
  staticDocuments: {
    initialized: false,
    documents: []
  },
  promotions: {
    data: [],
    included: [],
    meta: {
      active_rewards: {
        trees_planted: 0,
        months: 0,
        money_monthly: 0
      },
      pending_rewards: {
        months: 0,
        money_overall: 0
      },
      referral_rewards: {
        months: 0,
        money: {
          money_monthly: 0,
          months_duration: 0
        }
      }
    }
  },
  isCrowdInvestorAccountActive: false,
  transactions: {},
  transactionsInitialized: false,
  ABTests: [],
  profileBanners: [],
  financialSituation: null
};

const accountReducer = createReducer(
  initialState,
  on(AccountActions.getDocuments3BSuccess, (state, action) => {
    return {
      ...state,
      documents3B: {
        initialized: true,
        documents: action.documents
      }
    };
  }),
  on(AccountActions.getDocuments3BFail, (state) => {
    return {
      ...state,
      documents3B: {
        initialized: true,
        documents: []
      }
    };
  }),
  on(AccountActions.getDocuments3ASuccess, (state, action) => ({
    ...state,
    documents3A: {
      ...state.documents3A,
      initialized: true,
      documents: action.documents
    }
  })),
  on(AccountActions.getDocuments3AFail, (state) => ({
    ...state,
    documents3A: {
      ...state.documents3A,
      initialized: true,
      documents: []
    }
  })),
  on(AccountActions.getStaticDocumentsSuccess, (state, action) => ({
    ...state,
    staticDocuments: {
      initialized: true,
      documents: action.documents
    }
  })),
  on(AccountActions.getStaticDocumentsFail, (state) => ({
    ...state,
    staticDocuments: {
      initialized: true,
      documents: []
    }
  })),
  on(AccountActions.getDocumentsGrowSuccess, (state, action) => {
    return {
      ...state,
      documentsGrow: {
        initialized: true,
        documents: action.documents
      }
    };
  }),
  on(AccountActions.getDocumentsGrowFail, (state) => {
    return {
      ...state,
      documentsGrow: {
        initialized: true,
        documents: []
      }
    };
  }),
  on(AccountActions.getTransactionsSuccess, (state, action) => ({
    ...state,
    transactions: action.data,
    transactionsInitialized: true
  })),
  on(AccountActions.getPromotionsSuccess, (state, action) => ({
    ...state,
    promotions: action.data
  })),
  on(AccountActions.updateCurrentAccount, (state, action) => ({
    ...state,
    currentAccount: {
      ...state.currentAccount,
      ...action.data
    }
  })),
  on(AccountActions.setDocuments3APagination, (state, action) => ({
    ...state,
    documents3A: {
      ...state.documents3A,
      pagination: {
        range: action.range
      }
    }
  })),
  on(AccountActions.setCurrentAccount, (state, action) => ({
    ...state,
    currentAccount: action.data,
    transactionsInitialized: false
  })),
  on(AccountActions.setCurrentAccountID, (state, action) => ({
    ...state,
    currentAccount: {
      ...state.currentAccount,
      id: action.id
    }
  })),
  on(AccountActions.setAccountDetails, (state, action) => ({
    ...state,
    address: {
      city: action.data.city,
      country: action.data.country,
      number: action.data.number,
      street: action.data.street,
      zip: action.data.zip
    }
  })),
  // Remove app_location definition, after API starts return actual location
  on(AccountActions.setCustomer, (state, action) => ({
    ...state,
    customer: action.data
  })),
  on(AccountActions.setSwitchAccountLoading, (state, action) => ({
    ...state,
    switchAccountLoading: {
      show: action.show,
      message: action.message
    }
  })),
  on(AccountActions.changeLanguage, (state) => ({
    ...state,
    transactionsInitialized: false
  })),
  on(AccountActions.getABTestsSuccess, (state, action) => ({
    ...state,
    ABTests: action.data
  })),
  on(AccountActions.setCrowdInvestorAccountStatus, (state, action) => ({
    ...state,
    isCrowdInvestorAccountActive: action.active
  })),
  on(AccountActions.getProfileBannersSuccess, (state, action) => ({
    ...state,
    profileBanners: action.data
  })),
  on(AccountActions.getProfileBannersFail, (state) => ({
    ...state,
    profileBanners: []
  })),
  on(AccountActions.getFinancialSituationSuccess, (state, action) => ({
    ...state,
    financialSituation: action.data
  })),
  on(AccountActions.getFinancialSituationFail, (state) => ({
    ...state,
    financialSituation: null
  })),
  on(AccountActions.setInyovaGrowAccountFlags, (state, action) => ({
    ...state,
    customer: {
      ...state.customer,
      has_interest_account: action.has_interest_account,
      show_interest_account_banner: action.show_interest_account_banner
    }
  })),
  on(RootActions.toggleGrowAccount, (state, { active }) => ({ ...state, ...(active && { transactionsInitialized: false }) })),
  // NgRx store clean up after logout
  on(AccountActions.customerLogoutAndUnpairDevice, () => ({ ...initialState }))
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function reducer(state: AccountState | undefined, action: Action) {
  return accountReducer(state, action);
}

export interface AccountState {
  switchAccountLoading: {
    show: boolean;
    message: string;
  };
  address: Address;
  customer: Customer;
  currentAccount: CustomerAccount;
  documents3A: Documents3AStore;
  documents3B: {
    initialized: boolean;
    documents: AccountDocument[];
  };
  documentsGrow: {
    initialized: boolean;
    documents: GrowDocument[];
  };
  staticDocuments: {
    initialized: boolean;
    documents: StaticDocument[];
  };
  isCrowdInvestorAccountActive: boolean;
  promotions: Promotions;
  transactions: { [key: string]: Transaction[] };
  transactionsInitialized: boolean;
  ABTests: ABTest[];
  profileBanners: ProfileBannerItem[];
  financialSituation: FinancialSituation;
}

/*
 * SELECTORS
 * */
export const selectFeature = (state: State) => state.account;
// Account reducer selectors
export const selectCustomer = createSelector(selectFeature, (state: AccountState) =>
  state && state.customer ? state.customer : initialState.customer
);
export const selectHas3AAccountMinProspect = createSelector(selectCustomer, (state: Customer) =>
  state.accounts.some((item) => item.kind === '3a' && item.step_status >= 3)
);
export const selectCurrentAccount = createSelector(selectFeature, (state: AccountState) => {
  if (!state) {
    return initialState.currentAccount;
  }
  return state.currentAccount;
});
export const selectCurrentAccountID = createSelector(selectFeature, (state: AccountState) => state && state.currentAccount.id);
export const selectCrowdInvestorAccountActive = createSelector(selectFeature, (state: AccountState) => state?.isCrowdInvestorAccountActive);
export const selectCustomerAndCurrentAccount = createSelector(selectCustomer, selectCurrentAccount, (customer, currentAccount) => {
  return { customer, currentAccount };
});
export const selectDocuments3A = createSelector(selectFeature, (state: AccountState) => state.documents3A);
export const selectDocuments3APaged = createSelector(selectDocuments3A, (state: Documents3AStore) => {
  if (!state || !state.documents || !state.documents.length) {
    return {
      range: Documents3APagination.DEFAULT_RANGE,
      documents: [],
      totalNumber: 0,
      initialized: state.initialized
    };
  }

  const { range } = state.pagination;

  // Fix for tax report order
  const taxDocs = state.documents.filter((doc) => doc.attributes.document_type_code === 'AZST3');
  const otherDocs = state.documents.filter((doc) => doc.attributes.document_type_code !== 'AZST3');
  const allDocs = [...taxDocs, ...otherDocs];

  const documents = allDocs.slice(0, range - 1);

  return {
    range,
    documents,
    totalNumber: state.documents.length,
    initialized: state.initialized
  };
});

export const selectDocuments3B = createSelector(selectFeature, (state: AccountState) => {
  const { initialized } = state.documents3B;

  const reportingDocs = [];
  const taxDocs = [];
  const CIDocs = [];
  const otherDocs = [];

  const basicDocIDs = [5, 22, 23, 24, 42, 43];
  state.documents3B.documents.forEach((doc) => {
    if (doc.attributes.type_id === 22) {
      reportingDocs.push(doc);
    } else if (doc.attributes.type_id === 5) {
      taxDocs.push(doc);
    } else if (doc.attributes.type_id === 42 || doc.attributes.type_id === 43) {
      CIDocs.push(doc);
    } else if (!basicDocIDs.includes(doc.attributes.type_id)) {
      otherDocs.push(doc);
    }
  });
  CIDocs.sort((a, b) => a.attributes.type_id - b.attributes.type_id);
  otherDocs.sort((a, b) => b.attributes.type_id - a.attributes.type_id);

  return { initialized, taxDocs, reportingDocs, CIDocs, otherDocs };
});

export const selectDocumentsGrow = createSelector(selectFeature, (state: AccountState) => {
  const { initialized, documents } = state.documentsGrow;

  const groupedDocuments: { [key: string]: { title: string; documents: GrowDocument[] } } = documents.reduce(function (
    obj: { [key: string]: { title: string; documents: GrowDocument[] } },
    document
  ) {
    if (typeof obj[document.project_id] === 'undefined') {
      obj[document.project_id] = { title: document.project_title, documents: [document] };
      return obj;
    }
    obj[document.project_id].documents.push(document);
    return obj;
  }, {});

  const projects = Object.keys(groupedDocuments).map((key) => ({
    title: groupedDocuments[key].title,
    documents: groupedDocuments[key].documents
  }));
  return { initialized, projects };
});

export const selectTransfer3ADocument = createSelector(selectFeature, (state: AccountState) => {
  if (!state || !state.documents3B || !state.documents3B.initialized) return [];
  return state.documents3B.documents.filter((doc) => doc.attributes.type_id === 24);
});

export const selectStaticDocuments = createSelector(selectFeature, (state: AccountState) => {
  const { initialized } = state.staticDocuments;
  const docs = state.staticDocuments.documents;

  let static3aDocs: StaticDocument[] = [];
  let static3bDocs: StaticDocument[] = [];
  let agmDocs: StaticDocument[] = [];
  let egmDocs: StaticDocument[] = [];
  let CIUpdates: StaticDocument[] = [];

  for (const doc of docs) {
    switch (doc.attributes.account_type) {
      case 'crowd_investing':
        switch (doc.attributes.category) {
          case 'agm':
            agmDocs = [...agmDocs, doc];
            break;
          case 'egm':
            egmDocs = [...egmDocs, doc];
            break;
          case 'updates':
            CIUpdates = [...CIUpdates, doc];
            break;
          default:
            break;
        }
        break;
      case '3a':
        static3aDocs = [...static3aDocs, doc];
        break;
      case '3b':
        static3bDocs = [...static3bDocs, doc];
        break;
      default:
        break;
    }
  }

  return { initialized, CIUpdates, agmDocs, egmDocs, static3aDocs, static3bDocs };
});

export const selectAllDocuments = createSelector(
  selectDocuments3B,
  selectDocumentsGrow,
  selectDocuments3APaged,
  selectStaticDocuments,
  (docs3b, docsGrow, docs3a, docsStatic) => {
    return {
      docs3b,
      docsGrow,
      docs3a,
      docsStatic
    };
  }
);

export const selectTransactions = createSelector(selectFeature, (state: AccountState) => state.transactions);
export const selectTransactionsInitialized = createSelector(selectFeature, (state: AccountState) => state.transactionsInitialized);

export const selectTransactionsView = createSelector(
  selectCustomerAndCurrentAccount,
  selectTransactionsInitialized,
  selectTransactions,
  ({ customer, currentAccount }, loaded, transactions) => {
    const currentYear = new Date().getFullYear();

    return {
      appLocation: customer.app_location,
      currency: currentAccount.currency,
      depositThisYear: currentAccount.deposit_this_year,
      ownerName: currentAccount.owner_name,
      kind: currentAccount.kind,
      maxContributionLevel: currentAccount.max_contribution_level,
      loaded,
      transactions,
      inyovaFee: currentAccount.inyova_fee,
      currentYear,
      possibleYearlyDeposit: currentAccount.kind === '3a' ? MAXIMUM_3A_CONTRIBUTION[currentYear][currentAccount.max_contribution_level] : 0
    } as TransactionsView;
  }
);

export const selectPromotions = createSelector(selectFeature, (state: AccountState) => state.promotions);
export const selectHasActivePromotions = createSelector(selectFeature, (state: AccountState) => !!state?.promotions?.included);
export const selectPromotionsDataForRedeemCode = createSelector(selectPromotions, (state: Promotions) => {
  const defaults = {
    code: '',
    activatedAt: '',
    freeMonths: 0,
    moneyPrmotion: {
      amount: 0,
      period: 0
    }
  };

  if (!state.included || !state.included?.length) {
    return defaults;
  }

  const attributes = state.included[0]?.attributes;

  defaults.code = attributes.code;
  defaults.activatedAt = state.data[0]?.attributes?.valid_from;

  if (attributes.type === 'MoneyPromotion') {
    defaults.moneyPrmotion.amount = attributes.value;
    defaults.moneyPrmotion.period = Number(attributes.months_duration);
  }

  if (attributes.type === 'FreeMonthsPromotion') {
    defaults.freeMonths = Number(attributes.months_duration);
  }

  return defaults;
});
export const selectNeonPromotion = createSelector(selectPromotions, (state: Promotions) => {
  if (!state || !state.included || state.included.length === 0) {
    return false;
  }
  const neonPromotion = state.included.find((promotion) => promotion.attributes.code === 'neon3a');
  if (!neonPromotion) {
    return false;
  }
  const neonValidityDate = state.data.find((promotion) => Number(promotion.relationships.promotion.data.id) === Number(neonPromotion.id))
    .attributes.valid_from;
  const dayDifference = dayjs().diff(dayjs(neonValidityDate, 'YYYY-MM-DD'), 'day');
  return dayDifference < 60;
});
export const selectMultipleActivePromotions = createSelector(
  selectPromotions,
  selectCurrentAccount,
  selectNeonPromotion,
  (state: Promotions, currentAccount, neonPromotionActive) => {
    if (!state || !state.meta || !state.meta.active_rewards) {
      return false;
    }

    const multipleActivePromotions = Object.keys(state.meta.active_rewards).filter((key) => state.meta.active_rewards[key] !== 0).length;

    if (multipleActivePromotions > 1 || (multipleActivePromotions === 1 && neonPromotionActive && currentAccount.kind === '3a')) {
      return true;
    }

    return false;
  }
);
export const selectAccountDetails = createSelector(selectFeature, (state: AccountState) => {
  if (!state) return initialState.address;
  return state.address;
});
export const selectIsAccountLoading = createSelector(selectFeature, (state: AccountState) => {
  if (!state) return initialState.switchAccountLoading;
  return state.switchAccountLoading;
});

export const selectActiveABTest = (experimentID: string) =>
  createSelector(selectFeature, (state: AccountState) => {
    const concreteABTest = state.ABTests.find((element) => element.attributes.experiment_id === experimentID);
    return concreteABTest?.attributes.alternative === 'false' ? false : concreteABTest?.attributes.alternative;
  });

export const selectProfileBanners = createSelector(selectFeature, (state: AccountState) => {
  return state.profileBanners;
});

export const selectFinancialSituation = createSelector(selectFeature, (state: AccountState) => {
  return state.financialSituation;
});

export const selectSavingsPlanSocialProof = createSelector(
  selectFeature,
  (state: AccountState) => state.currentAccount.savings_plan_social_proof
);

export const selectHasGrowAccount = createSelector(selectCustomer, (customer: Customer) => customer.has_interest_account);
