import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'thousand',
  pure: true
})
export class ThousandSeparatorPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: number | string, currency?: string, addPlusChar?: boolean, decimals: number = 0): string {
    if (!value) {
      return this.addCurrency(currency, '0');
    }

    let amount = value;
    if (typeof value === 'string') {
      amount = Number(value);
    }

    if (decimals !== 0) {
      amount = Number(value).toFixed(decimals);
    }

    const outputValue = `${Number(value) > 0 && addPlusChar ? '+' : ''}${amount.toLocaleString(this.translateService.currentLang)}`;
    return this.addCurrency(currency, outputValue);
  }

  addCurrency(currency: string, outputValue: string): string {
    if (!currency) return outputValue;

    if (currency === 'CHF') {
      return `${currency} ${outputValue}`;
    }

    return `${outputValue} ${currency}`;
  }
}
