import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Share } from '@capacitor/share';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import SwiperCore, { Navigation, Pagination, Scrollbar, SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as HomeActions from '@home/home.actions';
import * as fromAccount from '@account/account.reducers';
import * as fromHome from '@home/reducers';

import { StatusBarStylingService } from '@app/shared/services/status-bar-styling.service';
import { HomeImpactCampaignSupportComponent } from '@home/modals';
import { CampaignVideoComponent } from '@home/modals/home-impact-campaign-video/campaign-video.component';
import { ImpactStoryDetail } from '@shared/models/Home';
import { LanguageService } from '@shared/services/language.service';
import { TrackingService } from '@shared/services/tracking.service';

// install Swiper modules
SwiperCore.use([Pagination, Navigation, Scrollbar]);

@Component({
  selector: 'app-home-impact-campaign',
  templateUrl: './home-impact-campaign.component.html',
  styleUrls: ['./home-impact-campaign.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeImpactCampaignComponent implements OnInit, OnDestroy {
  protected readonly onDestroy$ = new Subject<void>();
  @ViewChild('swiper') swiper: SwiperComponent;
  campaignDetail!: ImpactStoryDetail['attributes'];
  language = 'ch';
  selectedFAQIndex = null;
  loading = true;
  id: number;
  code: string;

  sliderConfig: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: -20,
    navigation: false,
    scrollbar: true
  };

  slideOrder = ['en', 'de', 'fr'];
  slides = [
    {
      lang: 'fr',
      assets: '/assets/images/slide-fr.png'
    },
    {
      lang: 'fr',
      assets: '/assets/images/slide-fr-2.png'
    },
    {
      lang: 'en',
      assets: '/assets/images/slide-en.png'
    },
    {
      lang: 'de',
      assets: '/assets/images/slide-de.png'
    }
  ];

  logos = [
    {
      link: 'https://www.greenpeace.org/international/press-release/54482/cannes-lions-awards-greenpeace-activist-and-former-winner-crashes-ceremony-to-call-for-a-ban-on-fossil-fuel-advertising/',
      assets: '/assets/images/greenpeace.png'
    },
    {
      link: 'https://banfossilfuelads.org/',
      assets: '/assets/images/ban-fosil.png'
    },
    {
      link: 'https://www.thedrum.com/opinion/2022/04/07/why-the-ipcc-shone-spotlight-ad-agencies-still-working-with-fossil-fuel-clients',
      assets: '/assets/images/ipcc.png'
    },
    {
      link: 'https://www.wwf.eu/?5796891/Campaign-Banning-fossil-fuel-adverts-to-help-solve-the-climate-crisis',
      assets: '/assets/images/wwf.png'
    },
    {
      link: 'https://www.provokemedia.com/latest/article/un-secretary-general-condemns-pr%27s-work-for-big-oil',
      assets: '/assets/images/un.png'
    },
    {
      link: 'https://fossilfree.media/',
      assets: '/assets/images/fossil-free.png'
    },
    {
      link: 'https://cleancreatives.org/learn-2022',
      assets: '/assets/images/clean-creatives.png'
    },
    {
      link: 'https://www.creativeandclimate.com/',
      assets: '/assets/images/creative-climate.png'
    }
  ];

  constructor(
    private store: Store,
    private languageService: LanguageService,
    private modalController: ModalController,
    private route: ActivatedRoute,
    private trackingService: TrackingService,
    private translateService: TranslateService,
    private statusBarStyling: StatusBarStylingService
  ) {}

  ngOnInit(): void {
    this.store.pipe(select(fromAccount.selectCustomer), takeUntil(this.onDestroy$)).subscribe((value) => {
      this.code = value.referral_code;
    });

    this.languageService.selected.pipe(takeUntil(this.onDestroy$)).subscribe((value) => {
      this.language = value;
      switch (this.language.split('-')[0]) {
        case 'fr':
          this.slideOrder = ['fr', 'en', 'de'];
          break;
        case 'de':
          this.slideOrder = ['de', 'en', 'fr'];
          break;
        default:
          this.slideOrder = ['en', 'de', 'fr'];
          break;
      }

      this.slides.sort((a, b) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        return this.slideOrder.indexOf(a.lang) - this.slideOrder.indexOf(b.lang);
      });
    });

    this.route.paramMap.subscribe((params) => {
      if (params.has('id')) {
        this.id = Number(params.get('id').valueOf());
        this.store.dispatch(HomeActions.getImpactStoryDetail({ id: this.id }));
      }
    });

    this.store.pipe(select(fromHome.selectImpactStoryDetail), takeUntil(this.onDestroy$)).subscribe((story) => {
      if (!story.article?.attributes?.title) {
        return;
      }

      this.campaignDetail = cloneDeep(story.article.attributes);
      this.campaignDetail.expired = new Date(story.article.attributes.expires_at) < new Date();
    });

    this.store.pipe(select(fromHome.selectImpactStoryDetailLoading), takeUntil(this.onDestroy$)).subscribe((value) => {
      this.loading = value;
    });
  }

  setSelectedFAQ(index: number): void {
    if (index === this.selectedFAQIndex) {
      this.selectedFAQIndex = null;
      return;
    }

    this.selectedFAQIndex = index;
    this.trackingService.trackActivity(`[Button] Open FAQ Detail - ${index}`);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  async openVideo(): Promise<void> {
    this.trackingService.trackActivity('[Button] Open campaign video');
    this.statusBarStyling.setBackgroundColor('dialog');
    const modal = await this.modalController.create({
      component: CampaignVideoComponent,
      componentProps: {
        videoURL: this.campaignDetail.link,
        liked: this.campaignDetail.liked,
        id: this.id
      },
      swipeToClose: false
    });
    void modal.onDidDismiss().then(() => {
      this.statusBarStyling.setBackgroundColor('base');
    });
    return modal.present();
  }

  engageCampaign(source: string = 'General'): void {
    this.trackingService.trackActivity(
      `[Button] ${source} - Open campaign ${this.campaignDetail.liked ? 'final heart' : 'handshake'} modal`
    );
    void this.openCampaignSupport();
  }

  async openCampaignSupport() {
    this.statusBarStyling.setBackgroundColor('dialog');
    const modal = await this.modalController.create({
      component: HomeImpactCampaignSupportComponent,
      cssClass: 'app-fullscreen green',
      componentProps: {
        campaignID: this.id,
        liked: this.campaignDetail.liked,
        shareholder: this.campaignDetail.shareholder,
        expired: this.campaignDetail.expired
      },
      swipeToClose: true,
      presentingElement: await this.modalController.getTop()
    });
    void modal.onDidDismiss().then(() => {
      this.statusBarStyling.setBackgroundColor('base');
    });
    return modal.present();
  }

  async shareCampaign(source: string) {
    this.trackingService.trackActivity(`[Button] ${source} - Campaign: Share CTA`);
    const key = this.campaignDetail.expired ? 'IMPACT.publicisCampaign.share.finished' : 'IMPACT.publicisCampaign.share.ongoing';
    await Share.share({
      text: this.translateService.instant(key, { code: this.code })
    });
  }

  trackLogos(link: string): void {
    this.trackingService.trackActivity(`[Link] Campaign Logo: ${link}`);
  }
}
