import { Component, Input, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as fromHome from '@home/reducers';

import { StatusBarStylingService } from '@app/shared/services/status-bar-styling.service';
import { HomeFakeDoorModalComponent } from '@home/components';
import { TrackingService } from '@shared/services/tracking.service';

@Component({
  selector: 'app-fake-door',
  styleUrls: ['./home-fake-door.component.scss'],
  templateUrl: './home-fake-door.component.html'
})
export class HomeFakeDoorComponent implements OnDestroy {
  @Input() type: 'portfolioReview' | 'depositControl';
  protected readonly onDestroy$ = new Subject<void>();
  fakeDoorScreen: number;

  constructor(
    private trackingService: TrackingService,
    private modalController: ModalController,
    private store: Store,
    private statusBarStyling: StatusBarStylingService
  ) {
    this.store.pipe(select(fromHome.selectFakeDoorStatus), takeUntil(this.onDestroy$)).subscribe((status) => {
      this.fakeDoorScreen = status ? 3 : 1;
    });
  }

  async openModal(): Promise<void> {
    this.trackingService.trackActivity(`[Button] Fake door - ${this.type}: CTA 1 clicked`);
    this.statusBarStyling.setBackgroundColor('dialog');
    const modal = await this.modalController.create({
      component: HomeFakeDoorModalComponent,
      componentProps: {
        type: this.type,
        screen: this.fakeDoorScreen
      },
      cssClass: 'app-fullscreen',
      swipeToClose: true,
      presentingElement: document.getElementById('ionRouterOutletId')
    });

    void modal.onDidDismiss().then(() => {
      this.trackingService.trackActivity(`[Button] Fake door - ${this.type}: modal closed`);
      this.statusBarStyling.setBackgroundColor('base');
    });

    return modal.present();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
