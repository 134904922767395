import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';

@Injectable({
  providedIn: 'root'
})
export class StatusBarStylingService {
  constructor() {}

  private StatusBarColor = {
    BASE: '#F8F6F1',
    DIALOG: '#A8A7A4',
    LOGIN: '#F3EEE1'
  };

  setStyle(type: 'light' | 'dark'): void {
    if (!Capacitor.isNativePlatform()) return;
    const style = type === 'light' ? Style.Light : Style.Dark;
    StatusBar.setStyle({
      style
    });
  }

  setBackgroundColor(type: 'dialog' | 'base' | 'login'): void {
    if (!Capacitor.isNativePlatform()) return;
    let color;
    switch (type) {
      case 'login':
        color = this.StatusBarColor.LOGIN;
        break;
      case 'dialog':
        color = this.StatusBarColor.DIALOG;
        break;
      default:
        color = this.StatusBarColor.BASE;
    }
    StatusBar.setBackgroundColor({
      color
    });
  }
}
