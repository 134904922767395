import { Action, createReducer, createSelector, on } from '@ngrx/store';
import * as InyovaGrowActions from './inyova-grow.actions';
import * as AccountActions from '@account/account.actions';

import {
  InyovaGrowAccount,
  InyovaGrowAccountStatus,
  InyovaGrowFund,
  InyovaGrowProject,
  InyovaGrowProjectStatus,
  InyovaGrowPercentages,
  InyovaGrowRisk,
  InyovaGrowInterestAccountProject,
  GrowPortfolioAllocation
} from '@app/shared/models/Grow';
import { groupRiskQuestions } from '@grow/utils/inyova-grow.utils';
import { State } from '@shared/models/State';

export const GROW_REDUCER_FEATURE_KEY = 'grow';

export interface InyovaGrowState {
  growAccount: InyovaGrowAccount;
  growProjectsEntities: { [id: string]: InyovaGrowProject };
  growProjectsLoading: boolean;
  growProjectsLoaded: boolean;
  growPortfolioAllocation: null | GrowPortfolioAllocation;
  growFund: null | InyovaGrowFund;
  growRisk: null | InyovaGrowRisk;
  growPercentages: null | InyovaGrowPercentages;
  growRiskLoading: boolean;
  growRiskLoaded: boolean;
  growCheckLoading: boolean;
  growCheckLoaded: boolean;
  growSubmitLoading: boolean;
  growIntroLoading: boolean;
  growInvestmentLoading: boolean;
  growInvestmentDone: boolean;
  growInvestmentFinishStatus: 'success' | 'reached' | '';
}

export const initialState: InyovaGrowState = {
  growAccount: {
    id: '',
    currency: 'CHF',
    customer_id: 0,
    fees_consent: false,
    status: null,
    initial_investment: null,
    monthly_investment: null,
    projects_percentage: null,
    portfolio_value: null,
    combined_expected_return: null,
    minimum_investment: 2000,
    iban: '',
    investment_risk_consent: false,
    customer_name: '',
    reporting_timestamp: '',
    inyova_and_custody_fees: null,
    product_costs: null,
    edit_risk_profile: null,
    eur_exchange_rate: 0
  },
  growProjectsEntities: {},
  growProjectsLoading: false,
  growProjectsLoaded: false,
  growPortfolioAllocation: null,
  growFund: null,
  growRisk: null,
  growPercentages: null,
  growRiskLoading: false,
  growRiskLoaded: false,
  growCheckLoading: false,
  growCheckLoaded: false,
  growSubmitLoading: false,
  growIntroLoading: false,
  growInvestmentLoading: false,
  growInvestmentDone: false,
  growInvestmentFinishStatus: ''
};

const growReducer = createReducer(
  initialState,
  on(InyovaGrowActions.getInyovaGrowAccountSuccess, (state, action) => ({
    ...state,
    growAccount: action.data
  })),
  on(InyovaGrowActions.createInyovaGrowAccount, (state, action) => ({
    ...state,
    growIntroLoading: true
  })),
  on(InyovaGrowActions.createInyovaGrowAccountSuccess, (state, action) => ({
    ...state,
    growAccount: action.data,
    growIntroLoading: false
  })),
  on(InyovaGrowActions.createInyovaGrowAccountFail, (state) => ({ ...state, growAccount: null, growIntroLoading: false })),
  on(InyovaGrowActions.getInyovaGrowIbanSuccess, (state, action) => ({
    ...state,
    growAccount: { ...state.growAccount, iban: action.iban }
  })),
  on(InyovaGrowActions.getInyovaGrowProjects, (state) => ({
    ...state,
    growProjectsLoading: true
  })),
  on(InyovaGrowActions.getInyovaGrowProjectsSuccess, (state, { fund, projects }) => {
    const growProjectsEntities = projects.reduce(
      (entities: { [id: string]: InyovaGrowProject }, projectItem: InyovaGrowProject) => {
        return {
          ...entities,
          [projectItem.id]: projectItem
        };
      },
      { ...state.growProjectsEntities }
    );

    return {
      ...state,
      growProjectsEntities,
      growFund: fund,
      growProjectsLoading: false,
      growProjectsLoaded: true
    };
  }),
  on(InyovaGrowActions.getInyovaGrowProjectsFail, (state) => {
    return {
      ...state,
      growProjectsLoading: false,
      growProjectsLoaded: false
    };
  }),
  on(AccountActions.changeLanguage, (state) => ({
    ...state,
    growProjectsLoaded: false
  })),
  on(InyovaGrowActions.getInyovaGrowPortfolioAllocationSuccess, (state, { growPortfolioAllocation }) => ({
    ...state,
    growPortfolioAllocation
  })),
  on(InyovaGrowActions.selectInyovaGrowProject, (state) => {
    return {
      ...state
    };
  }),
  on(InyovaGrowActions.selectInyovaGrowProjectSuccess, (state, { projectId, interestAccountProject }) => {
    return {
      ...state,
      growProjectsEntities: {
        ...state.growProjectsEntities,
        [projectId]: {
          ...state.growProjectsEntities[projectId],
          interest_account_project: {
            ...interestAccountProject
          }
        }
      }
    };
  }),
  on(InyovaGrowActions.selectInyovaGrowProjectFail, (state) => {
    return {
      ...state
    };
  }),
  on(InyovaGrowActions.deselectInyovaGrowProject, (state) => {
    return {
      ...state
    };
  }),
  on(InyovaGrowActions.deselectInyovaGrowProjectSuccess, (state, { projectId }) => {
    return {
      ...state,
      growProjectsEntities: {
        ...state.growProjectsEntities,
        [projectId]: {
          ...state.growProjectsEntities[projectId],
          interest_account_project: {
            status: InyovaGrowProjectStatus.INITIAL
          } as InyovaGrowInterestAccountProject
        }
      }
    };
  }),
  on(InyovaGrowActions.deselectInyovaGrowProjectFail, (state) => {
    return {
      ...state
    };
  }),
  on(InyovaGrowActions.getInyovaGrowRisk, (state) => {
    return {
      ...state,
      growRiskLoading: true
    };
  }),
  on(InyovaGrowActions.getInyovaGrowRiskSuccess, (state, { data, percentages }) => {
    return {
      ...state,
      growRisk: data,
      growPercentages: percentages,
      growRiskLoading: false,
      growRiskLoaded: true,
      growCheckLoaded: state.growAccount.projects_percentage !== null
    };
  }),
  on(InyovaGrowActions.getInyovaGrowRiskFail, (state) => {
    return {
      ...state,
      growRiskLoading: false,
      growRiskLoaded: false
    };
  }),
  on(InyovaGrowActions.checkGrowRiskProfile, (state) => {
    return {
      ...state,
      growCheckLoading: true
    };
  }),
  on(InyovaGrowActions.checkGrowRiskProfileSuccess, (state, { data }) => {
    return {
      ...state,
      growPercentages: data,
      growCheckLoading: false,
      growCheckLoaded: true
    };
  }),
  on(InyovaGrowActions.checkGrowRiskProfileFail, (state) => {
    return {
      ...state,
      growCheckLoading: false,
      growCheckLoaded: false
    };
  }),
  on(InyovaGrowActions.updateInyovaGrowRisk, (state) => ({
    ...state,
    growSubmitLoading: true,
    ...(state.growAccount.status === InyovaGrowAccountStatus.FUNDED && { growProjectsLoaded: false })
  })),
  on(InyovaGrowActions.updateInyovaGrowRiskSuccess, (state, { data }) => {
    const { interest_account, ...risk } = data;

    return {
      ...state,
      growSubmitLoading: false,
      growAccount: {
        ...state.growAccount,
        ...interest_account
      },
      growRisk: {
        ...state.growRisk,
        ...risk
      },
      growCheckLoaded: interest_account.projects_percentage !== null
    };
  }),
  on(InyovaGrowActions.updateInyovaGrowRiskFail, (state) => ({
    ...state,
    growSubmitLoading: false
  })),
  on(InyovaGrowActions.finishGrowProjectInvestment, (state) => ({
    ...state,
    growInvestmentLoading: true
  })),
  on(InyovaGrowActions.finishGrowProjectInvestmentSuccess, (state, { projectId }) => ({
    ...state,
    growInvestmentDone: true,
    growInvestmentFinishStatus: 'success',
    growInvestmentLoading: false,
    growProjectsLoaded: false,
    growPortfolioAllocation: null,
    growAccount: { ...state.growAccount, edit_risk_profile: false },
    growProjectsEntities: {
      ...state.growProjectsEntities,
      [projectId]: {
        ...state.growProjectsEntities[projectId],
        interest_account_project: {
          ...state.growProjectsEntities[projectId].interest_account_project,
          status: InyovaGrowProjectStatus.COMMITTED
        }
      }
    }
  })),
  on(InyovaGrowActions.finishGrowProjectInvestmentReached, (state) => ({
    ...state,
    growInvestmentDone: true,
    growInvestmentFinishStatus: 'reached',
    growInvestmentLoading: false,
    growProjectsLoaded: false
  })),
  on(InyovaGrowActions.finishGrowProjectInvestmentFail, InyovaGrowActions.finishGrowProjectInvestmentReset, (state) => ({
    ...state,
    growInvestmentDone: false,
    growInvestmentSuccess: false,
    growInvestmentFinishStatus: '',
    growInvestmentLoading: false
  })),
  // NgRx store clean up after logout
  on(AccountActions.customerLogoutAndUnpairDevice, () => ({ ...initialState }))
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function reducer(state: InyovaGrowState | undefined, action: Action) {
  return growReducer(state, action);
}

/*
 * SELECTORS
 * */
export const selectFeature = (state: State) => state.grow;
// Account reducer selectors
export const selectInyovaGrowAccount = createSelector(selectFeature, (state: InyovaGrowState) => state.growAccount);
export const selectInyovaGrowAccountID = createSelector(selectInyovaGrowAccount, (account: InyovaGrowAccount) => account && account.id);
export const selectInyovaGrowAccountStatus = createSelector(selectInyovaGrowAccount, (account: InyovaGrowAccount) => account.status);
export const selectInyovaGrowIntroLoading = createSelector(selectFeature, (state: InyovaGrowState) => state.growIntroLoading);

export const selectInyovaGrowProjectsLoaded = createSelector(selectFeature, (state: InyovaGrowState) => state.growProjectsLoaded);
export const selectInyovaGrowPortfolioAllocation = createSelector(selectFeature, (state: InyovaGrowState) => state.growPortfolioAllocation);
export const selectInyovaGrowProjectsEntities = createSelector(selectFeature, (state: InyovaGrowState) => state.growProjectsEntities);
export const selectInyovaGrowProjects = createSelector(selectInyovaGrowProjectsEntities, (entities) => {
  return Object.keys(entities).map((id) => entities[id]);
});
export const selectInyovaGrowProjectById = (id: string) =>
  createSelector(selectInyovaGrowProjectsEntities, (entities) => {
    return entities[id];
  });
export const selectInyovaGrowFund = createSelector(selectFeature, (state: InyovaGrowState) => state.growFund);
export const selectHasSelectedProject = createSelector(selectInyovaGrowProjects, (inyovaGrowProjects) => {
  return inyovaGrowProjects.some((project) => project.interest_account_project.status === InyovaGrowProjectStatus.INTERESTED);
});

export const selectInyovaGrowRiskLoaded = createSelector(selectFeature, (state: InyovaGrowState) => state.growRiskLoaded);
export const selectGrowRisk = createSelector(selectFeature, (state) => state.growRisk);
export const selectGrowOnboardingId = createSelector(selectGrowRisk, (state) => state.id);
export const selectInyovaGrowRisk = createSelector(selectGrowRisk, selectInyovaGrowAccount, (growRisk, growAccount) => {
  if (growRisk === null) {
    return { growAccount, surveyGroups: null, financialDetail: null };
  }
  return {
    growAccount,
    surveyGroups: groupRiskQuestions(growRisk.risk_survey.survey_questions),
    financialDetail: growRisk.financial_detail
  };
});
export const selectInyovaGrowPercentages = createSelector(selectFeature, (state) => state.growPercentages);

export const selectInyovaGrowCheckLoading = createSelector(selectFeature, (state: InyovaGrowState) => state.growCheckLoading);
export const selectInyovaGrowCheckLoaded = createSelector(selectFeature, (state: InyovaGrowState) => state.growCheckLoaded);
export const selectInyovaGrowSubmitLoading = createSelector(selectFeature, (state: InyovaGrowState) => state.growSubmitLoading);
export const selectInyovaGrowInvestmentLoading = createSelector(selectFeature, (state: InyovaGrowState) => state.growInvestmentLoading);
export const selectInyovaGrowInvestmentDone = createSelector(selectFeature, (state: InyovaGrowState) => state.growInvestmentDone);
export const selectInyovaGrowFinishStatus = createSelector(selectFeature, (state: InyovaGrowState) => state.growInvestmentFinishStatus);
