import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

type valueType = string | number | boolean;

@Component({
  selector: 'app-ds-radio-option',
  templateUrl: './ds-radio-option.component.html',
  styleUrls: ['./ds-radio-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DsRadioOptionComponent),
      multi: true
    }
  ]
})
export class DsRadioOptionComponent implements ControlValueAccessor {
  @Input() value: valueType;
  @Input() selected: valueType;

  @Input() variant: 'default' | 'box' = 'default';

  @Input() disabled = false;
  @Input() hasError: boolean;
  @Input() errorMessage: string;

  get checked() {
    return this.selected === this.value;
  }

  @Output() clicked = new EventEmitter();

  onChange: any = () => {};
  onTouch: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: valueType) {
    this.selected = value;
  }

  onModelChange(e: valueType) {
    this.selected = e;
    this.onChange(e);
  }

  onClick() {
    if (this.disabled) return;
    this.clicked.emit(this.value);
    this.onModelChange(this.value);
  }
}
