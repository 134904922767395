import { LucideIconData, LucideIcons } from 'lucide-angular/icons/types';
import { parseSync } from 'svgson';

function svgToLucideIconData(svgString: string): LucideIconData {
  const parsed = parseSync(svgString);
  return parsed.children.map((n: any) => [n.name, n.attributes]);
}

function getCustomIconList(): LucideIcons {
  return {
    // this is the icon name! must be uppercase!
    Inyova: svgToLucideIconData(`
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 11.9871V3H3C3 7.96352 7.0336 12 11.9936 12H3V21C7.9664 21 12 16.9699 12 12H21V3C16.04 3 12.0065 7.03004 12 11.9871Z" />
      </svg>
    `),
    Renewable: svgToLucideIconData(`
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.516 3.00947 16.931 3.99122 18.74 5.74L21 8"/>
        <path d="M12 7L9 12H14.5L11.5 17"/>
        <path d="M21 3V8H16"/>
        <path d="M21 12C21 14.3869 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.3869 21 12 21C9.48395 20.9905 7.06897 20.0088 5.26 18.26L3 16"/>
        <path d="M8 16H3V21"/>
      </svg>
    `),
    NoCO2: svgToLucideIconData(`
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 2L22 22" />
        <path d="M20 10V14.5" />
        <path d="M16 10V11" />
        <path d="M16 10H20" />
        <path d="M16 7C16 4.5 14 4.5 14 2" />
        <path d="M20 7C20 4.5 18 4.5 18 2" />
        <path d="M20 20.5103C20 20.9054 19.8525 21.2843 19.5899 21.5637C19.3274 21.8431 18.9713 22 18.6 22H3.5C3.1287 22 2.7726 21.8431 2.51005 21.5637C2.2475 21.2843 2.1 20.9054 2.1 20.5103V12L7 15V12L12.5 15.5V13" />
        <path d="M11 18H12" />
        <path d="M6 18H7" />
      </svg>
    `),
    DsEdit: svgToLucideIconData(`
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18.5 2.50023C18.8978 2.1024 19.4374 1.87891 20 1.87891C20.5626 1.87891 21.1022 2.1024 21.5 2.50023C21.8978 2.89805 22.1213 3.43762 22.1213 4.00023C22.1213 4.56284 21.8978 5.1024 21.5 5.50023L12 15.0002L8 16.0002L9 12.0002L18.5 2.50023Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    `)
  };
}

export const customIcons = getCustomIconList();
