import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperModule } from 'swiper/angular';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { HomeEffects } from './home.effects';

import { SharedModule } from '@shared/shared.module';

import * as fromComponents from './components';
import { HomePageRoutingModule } from './home-routing.module';
import { HomePageComponent } from './home.page';
import * as fromModals from './modals';
import * as allReducers from './reducers/index';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    SwiperModule,
    EffectsModule.forFeature([HomeEffects]),
    HomePageRoutingModule,
    StoreModule.forFeature(allReducers.IMPACT_REDUCER_FEATURE_KEY, allReducers.fromImpact.reducerImpact),
    StoreModule.forFeature(allReducers.HOME_REDUCER_FEATURE_KEY, allReducers.fromHome.reducerHome),
    StoreModule.forFeature(allReducers.PERFORMANCE_REDUCER_FEATURE_KEY, allReducers.fromPerformance.reducerPerformance),
    TranslateModule
  ],
  declarations: [HomePageComponent, ...fromComponents.components, ...fromModals.components]
})
export class HomePageModule {}
